<template>
  <div class="fill-heigt pt-0" fluid>
    <v-row class="">
      <v-col cols="12" md="6" class="text-left px-3 mt-1">
        <!-- <pre>{{ vacancyId }}</pre> -->
        <!-- faixas<pre>{{ faixas }}</pre> -->
        <!--  faixa_80_90<pre>{{ faixa_80_90 }}</pre>
        faixa_90_100 <pre>{{ faixa_90_100 }}</pre> -->
        <!-- <pre>{{ this.vacancies.find((v) => v.id == this.vacancyId).favorites }}</pre> -->
        <v-row>
          <div class="text-left black--text pt-7">
            <div class="text-left px-3 black--text">
              {{ $t("candidancy") }}
            </div>
          </div>
          <!-- <pre>{{ vacancy.candidacies.length }}</pre> -->
          <!-- <a
              class="text-left black--text px-0 pt-7"
              @click="$emit('viewExpo')"
            >
              {{ $t("spontaneou_application") }}
            </a> -->
        </v-row>
      </v-col>

      <v-col cols="12" md="1" class="px-9 mt-2">
        <v-row>
          <div class="pt-3 px-12">
            <template>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" class="mx-4">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <div v-html="$t('tooltip_candidate_filter')"></div>
              </v-tooltip>
            </template>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="text-right px-6 mt-2">
        <v-row>
          <div class="px-0 pt-3 px-2">
            <!-- {{ vacancyId }} -->
            <candidate-filter
              v-if="vacancyId"
              :database="false"
              :vacancies="vacancies"
              :vacancyId="vacancyId"
              @apply="applyCandidateFilter"
              @change="setVacancy"
              @clean="applyCandidateFilter"
              @search="search"
            />
          </div>
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('searchCandidate')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>
    <v-row class="my-3">
      <v-col cols="12" md="6">
        <v-row class="align-center">
          <!-- <v-checkbox v-model="selectAll" color="primary" class="px-2" @change="checkSelectAll">
            <template v-slot:label class="pt-50">
              <div>{{ $t("select") }}</div>
            </template>
          </v-checkbox> -->
          <div style="width: 50%" class="">
            <v-row class="mx-3 mt-0">
              <v-autocomplete
                v-model="filter.favorites"
                :items="lists"
                item-text="name"
                item-value="candidates"
                :loading="loading"
                hide-no-data
                outlined
                dense
                clearable
                :label="$t('favorites')"
                class="mb-2"
                hide-details
                @change="applyFilter()"
                @click="getAllMatch"
              ></v-autocomplete>
            </v-row>
          </div>
          <v-row class="mx-2">
            <!-- <pre>
                {{Length.length}}
              </pre> -->
            <v-badge
              bordered
              color="success"
              icon="mdi-lock"
              :content="Length"
              overlap
              v-if="Length > 0"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="white--text"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    depressed
                    small
                    @click="gotoUnsolicited()"
                  >
                    <v-icon>mdi-folder-open</v-icon>
                  </v-btn>
                </template>
                <div v-html="$t('spontaneous_applications')"></div>
              </v-tooltip>
            </v-badge>
            <v-tooltip right v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-folder-open</v-icon>
              </template>
              <div v-html="$t('spontaneous_applications')"></div>
            </v-tooltip>
            <!-- <v-btn text x-small color="primary" @click="gotoUnsolicited()"
              ><span class="black--text">{{
                $t("spontaneous_applications")
              }}</span></v-btn
            > -->
          </v-row>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-row>
          <v-spacer></v-spacer>
          <template>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-if="accessLevel(currentUser, 'send_test')"
                  :disabled="!isSelected"
                  @click="dialogTest = true"
                  color="primary"
                >
                  <v-icon left>mdi-send</v-icon>
                </v-btn>
              </template>
              <div v-html="sendTestBtnLabel"></div>
            </v-tooltip>
          </template>

          <template>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-if="accessLevel(currentUser, 'schedule_interview')"
                  :disabled="!isSelected"
                  color="primary"
                  @click="dialogInterview = true"
                  style="margin-left: 5px"
                >
                  <v-icon left>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <div v-html="scheduleInterviewBtnLabel"></div>
            </v-tooltip>
          </template>
          <v-btn
            v-if="accessLevel(currentUser, 'schedule_interview')"
            :disabled="!isSelected"
            color="primary"
            @click="dialogRejectAprove = true"
            style="margin-left: 5px"
            class="mx-3"
          >
            <span>
              {{ $t("aprove_or_reject") }}
            </span>
          </v-btn>
        </v-row>
        <v-btn
          v-if="accessLevel(currentUser, 'schedule_interview')"
          :disabled="!isSelected"
          color="primary"
          @click="dialogInterview = true"
          style="margin-left: 5px; display: none"
        >
          <v-icon left>mdi-calendar</v-icon>{{ $t("schedule_interview") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          v-model="selectedCandidates"
          :headers="headers"
          :items="filteredItems"
          show-select
          show-expand
          expand-icon="mdi-eye"
          color="primary"
          class="elevation-1"
          dense
          single-expand
          :no-data-text="$t('tooltip_candidate')"
          @item-selected="itemSelected"
          @toggle-select-all="toggleSelectAll"
          :options.sync="options"
          :server-items-length="vacancy ? candidates.totalDocs : 0"
          :loading="loading"
        >
          <template v-slot:item.photo="{ item }">
            <!-- <pre>{{ item.blocked }}</pre> -->
            <v-badge
              :color="item.blocked ? 'primary' : 'transparent'"
              :icon="item.blocked ? 'mdi-lock' : ''"
              overlap
              :value="item.blocked"
            >
              <v-avatar size="40px" style="">
                <img
                  :src="
                    item.photo
                      ? `${apiUrl}/images/candidate/${item.photo}`
                      : `/avatar.png`
                  "
                  alt="perfil"
                />
              </v-avatar>
            </v-badge>
          </template>
          <template v-slot:item.name="{ item }">
            <span class="text-caption">{{
              item.name + " " + item.lastName
            }}</span>
          </template>
          <template v-slot:item.experience="{ item }"
            ><span class="text-caption"
              >{{ days(item.professionalExperience) }} {{ $t("year")
              }}{{ days(item.professionalExperience) > 1 ? "s" : "" }}</span
            >

            <!-- <pre>{{ item.professionalExperience }}</pre> -->
          </template>
          <template v-slot:item.punctuation="{ item }">
            <!-- <pre>{{ item.id }}</pre> -->
            <v-chip
              color="primary"
              label
              small
              v-if="parseFloat(match(item)) >= 50"
              ><span class="text-caption black--text">
                {{
                  match(item) > 100
                    ? 100
                    : match(item).toString().substring(0, 4)
                }}%
              </span></v-chip
            >
            <v-chip color="transparent" label small v-else>
              <span class="text-caption black--text"
                >{{
                  match(item) > 100
                    ? 100
                    : match(item).toString().substring(0, 4)
                }}%</span
              ></v-chip
            >
          </template>

          <template v-slot:item.specialDate="{ item }">
            <span
              v-if="
                getCandidancy(item.id) &&
                getCandidancy(item.id).created !== undefined
              "
            >
              {{ formatDate(getCandidancy(item.id).created) }}
            </span>
          </template>
          <template v-slot:item.email="{ item }">
            <template>
              <v-icon v-on="on" color="primary" @click="openDialogEmail(item)"
                >mdi-email
              </v-icon>
            </template>
          </template>
          <template v-slot:item.notes="{ item }">
            <v-icon v-on="on" color="primary" @click="openDialogNote(item)"
              >mdi-comment-edit</v-icon
            >
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <!-- <pre>{{ vacancies }} </pre> -->
              <v-row class="mb-1" style="margin-top: -45px">
                <profile-details
                  @updateList="fetchAutoList()"
                  :flat="true"
                  :candidate_id="item.id"
                  :list="faixas"
                  :vacancies="vacancies"
                  :filter="filter"
                  :vacancy_Id="vacancy"
                />
              </v-row>
            </td>
          </template>
        </v-data-table>
        <div>
          <v-progress-linear
            :active="loading"
            :indeterminate="true"
          ></v-progress-linear>
        </div>
      </v-col>
    </v-row>

    <!-- <div class="d-flex justify-center">
      <div justify="center" v-show="filteredItems.length < 1">
        <div class="grey--text" v-html="$t('tooltip_candidate')"></div>
      </div>
    </div> -->

    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <schedule-interview-dialog
      :dialog="dialogInterview"
      :selectedCandidates="selectedCandidates"
      :vacancyId="vacancyId"
      @close="dialogInterview = false"
    />
    <select-test
      :selectedCandidates="selectedCandidates"
      :dialog="dialogTest"
      :vacancyId="vacancyId"
      @close="dialogTest = false"
      @done="dialogTest = false"
      v-if="dialogTest"
    />

    <send-email-to-candidate
      :dialog="dialogEmail"
      :candidate="candidate.candidate"
      @close="closeAddDialog()"
      @done="sendEmailDone()"
    ></send-email-to-candidate>
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <!-- <pre>{{ candidate.candidate }}</pre> -->
    <note-candidate-dialog
      :candidateId="candidate.candidate"
      :dialog="dialogNote"
      :ranking="false"
      :vacancyId="vacancies.find((v) => v.id === vacancyId)"
      v-if="dialogNote && candidate.candidate && vacancyId"
      @close="dialogNote = false"
    />
    <approve-reject
      :dialog="dialogRejectAprove"
      :selectedCandidates="selectedCandidates"
      :vacancyId="vacancyId"
      :vacancy="this.vacancy"
      @getStage="getStage"
      @close="dialogRejectAprove = false"
      @reprove="
        dialogRejectReject = true;
        dialogRejectAprove = false;
      "
      @fetch="fetchVacancies()"
    />

    <reject-candidate-dialog
      :dialog="dialogRejectReject"
      :selectedCandidates="selectedCandidates"
      :vacancyId="vacancyId"
      :vacancy="this.vacancy"
      :stage="stage"
      @close="dialogRejectReject = false"
      @fetch="fetchVacancies()"
    />

    <SuccessDialogInterview />
    <infoDialogStage />

    <div id="sensor"></div>
  </div>
</template>

<script>
import { GET_PUBLISHED_VACANCIES_QUERY } from "../graphql/Query.resolver";
import {
  GET_CANDIDATES_ENTITY_QUERY,
  GET_UNSOLICITED_CANDIDATE,
  GET_CANDIDATES_ENTITY_SEARCH,
} from "./../../candidate/graphql/Query";
import {
  ADD_CANDIDATE_TO_VACANCY_LIST_MUTATION,
  CREATE_VACANCY_LIST_MUTATION,
  DELETE_VACANCY_LIST_MUTATION,
  REMOVE_CANDIDATE_FROM_VACANCY_LIST_MUTATION,
} from "../graphql/Mutation.resolver";
import { CANDIDATE_CANDIDACY_SUBSCRIPTION } from "./../graphql/Subscraption.resolver";
import scrollMonitor from "scrollmonitor";
import { mapActions, mapGetters } from "vuex";
// import CandidateCard from "../../../components/CandidateCard.vue";
import CandidateFilter from "../../../components/CandidateFilter.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
// import Pagination from '../../../components/Pagination.vue'
import ScheduleInterviewDialog from "./../components/ScheduleInterviewDialog";
import SelectTest from "./../components/SelectTest.vue";
// import SkeletonLoading from "../../../components/SkeletonLoading.vue";
// import InfiniteScroll from "../../../components/InfiniteScroll.vue";
import accessLevelMixins from "@/mixins/access-level";
import matchCandidate from "@/mixins/matchCandidate";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import yearsMixins from "@/mixins/years";
import { formatError } from "@/utils";
import { API_URL } from "@/api";
import SendEmailToCandidate from "./../components/SendEmailToCandidate.vue";
import SuccessDialog from "@/modules/entity/components/SuccessDialog.vue";
import NoteCandidateDialog from "./../components/NoteCandidateDialog";
import ProfileDetails from "./Profile.vue";
import SuccessDialogInterview from "../../interview/components/SuccessDialog.vue";
import infoDialogStage from "../../interview/components/infoDialog.vue";
import ApproveReject from "./../components/ApproveReject.vue";
import RejectCandidateDialog from "./../components/RejectCandidateDialog.vue";
import moment from "moment";
export default {
  name: "Candidancy",
  mixins: [
    accessLevelMixins,
    replaceSpecialCharsMixins,
    yearsMixins,
    matchCandidate,
  ],
  components: {
    // CandidateCard,
    CandidateFilter,
    InfoViewDialog,
    // Pagination,
    ScheduleInterviewDialog,
    SelectTest,
    // SkeletonLoading,
    SendEmailToCandidate,
    SuccessDialog,
    NoteCandidateDialog,
    ProfileDetails,
    SuccessDialogInterview,
    infoDialogStage,
    ApproveReject,
    RejectCandidateDialog,
    // InfiniteScroll,
  },
  data: () => ({
    dialogRejectAprove: false,
    dialogRejectReject: false,
    stage: "",
    candidates: [],
    candidacies: [],
    appliedFilter: false,
    dialogEmail: false,
    showSuccess: false,
    dialogNote: false,
    success: "",
    blockeds: [],
    candidate: {
      candidate: {},
      notes: [],
    },
    value: 0,
    apiUrl: API_URL,
    dialogInterview: false,
    dialogTest: false,
    filter: {
      areasInterest: undefined,
      province: undefined,
      otherSkills: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      limit: 10,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
      vacancyId: undefined,
      sector: undefined,
      name: undefined,
    },
    infoDialog: false,
    loadingData: true,
    skipQuery: true,
    selectAll: false,
    selectedCandidates: [],
    totalCandidates: null,
    textSearch: "",
    // totalPages: 1,
    vacancyId: null,
    vacancies: [],
    vacancies_page: 1,
    pageSize: 1,
    listCandidade: false,
    Length: 0,
    scrollPosition: 0,
    faixa_60_80: [],
    faixa_80_90: [],
    faixa_90_100: [],
    faixas: [],
    half: 0.5,
    loading: false,
    options: {},
  }),
  apollo: {
    vacancies: {
      query: GET_PUBLISHED_VACANCIES_QUERY,
      // fetchPolicy: "no-cache",
      variables() {
        return {
          page: this.vacancies_page,
          pageSize: this.pageSize,
          vacancyId: this.$route.query.vacancyId
            ? this.$route.query.vacancyId
            : undefined,
        };
      },
    },
    Length: {
      query: GET_UNSOLICITED_CANDIDATE,
      // fetchPolicy: "no-cache",
    },
    candidates: {
      query: GET_CANDIDATES_ENTITY_QUERY,
      // fetchPolicy: "no-cache",
      variables() {
        return { filterCandidate: this.filter };
      },
      skip() {
        return this.skipQuery;
      },
      result({ loading }) {
        if (!loading) {
          this.loadingData = false;
        }
      },
    },
    $subscribe: {
      candidateCandidacy: {
        query: CANDIDATE_CANDIDACY_SUBSCRIPTION,
        result({ data }) {
          this.candidates.candidates.push(
            data.candidateCandidacy.candidacy.candidate
          );
          this.applyFilter();
        },
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    vacancies: function (val) {
      console.log(val);
      if (!this.vacancyId && val.length > 0) {
        this.vacancyId = val.length > 0 ? val[0].id : undefined;
        // this.vacancy.favorites.forEach(fav=>{
        //     this.deleteList(fav.id);
        // })

        this.changeVacancy();
      } else if (val.length > 0) {
        this.changeVacancy();
      }
    },
    candidates: function (val) {
      console.log(val);
      // this.createAutoList(val.candidates);

      // this.vacancy.favorites.forEach(fav=>{
      //     this.deleteList(fav.id);
      // })
    },

    vacancyId(val) {
      if (val) {
        this.applyFilter();
      }
    },
    // vacancy: function (val) {
    //   if (val)
    //     if (this.getCandidancy.tests.filter((t) => t.stage).length == 0) {
    //       this.half = 1;
    //     } else {
    //       this.half = 0.5;
    //     }
    // },

    textSearch(val) {
      if (val) {
        console.log(val);
        this.filter.name = val;
        this.applyFilter();
      } else if (val === "") {
        this.filter.name = undefined;
        this.filter.limit = 10;
        this.options.itemsPerPage = 10;
        this.refetchCandidates();
      } else {
        this.filter.name = undefined;
        this.options.itemsPerPage = 10;
        this.filter.limit = 10;
        this.refetchCandidates();
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      modules: "auth/getModules",
    }),

    sendTestBtnLabel() {
      return (
        this.$t("send_test") + ": " + this.$t("tooltip_sendtest_candidate")
      );
    },
    scheduleInterviewBtnLabel() {
      return (
        this.$t("schedule_interview") + ": " + this.$t("tooltip_interview")
      );
    },
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "photo",
          width: "0.1%",
        },

        {
          text: this.$t("form_candidate_name"),
          align: "start",
          sortable: true,
          value: "name",
          width: "20%",
        },
        {
          text: this.$t("form_candidate_job"),
          align: "start",
          sortable: true,
          value: "profession",
          width: "15%",
        },
        {
          text: this.$t("years_experience"),
          align: "start",
          sortable: true,
          value: "experience",
          width: "20%",
        },
        {
          text: this.$t("match"),
          sortable: true,
          value: "punctuation",
          width: "17%",
        },
        {
          text: this.$t("submission_date"),
          align: "center",
          sortable: false,
          value: "specialDate",
          width: "15%",
        },
        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "email",
          width: "0%",
        },
        {
          text: this.$t("Notepad"),
          align: "center",
          sortable: false,
          value: "notes",
          width: "20%",
        },
        {
          text: this.$t("form_candidate_profile"),
          align: "center",
          sortable: false,
          // value: "profile",
          value: "data-table-expand",
        },
      ];
    },
    filteredItems() {
      let resultItems = this.candidates.candidates
        ? this.candidates.candidates
        : [];
      // if (this.textSearch) {
      //   const text = this.replaceSpecialChars(this.textSearch);
      //   resultItems = resultItems.filter(
      //     (t) =>
      //       this.replaceSpecialChars(t.name + " " + t.lastName).includes(
      //         text
      //       ) ||
      //       (t.profession &&
      //         this.replaceSpecialChars(t.profession).includes(text))
      //   );
      // }
      resultItems = this.sortByPontuation(resultItems);
      return resultItems;
    },
    isSelected() {
      return this.selectedCandidates.length > 0;
    },
    lists() {
      // return []
      return this.vacancies && this.vacancies.length
        ? this.vacancies
            .find((v) => v.id == this.vacancyId)
            .favorites.map((fav) => {
              return { ...fav, name: this.$t(fav.name) };
            })
            .concat(this.faixas)
        : [];
    },
    vacancy() {
      if (this.vacancies.length) {
        return this.vacancies.find((v) => v.id === this.vacancyId);
      } else {
        return null;
      }
    },
    lastModules() {
      return this.modules.length - 1;
    },
    infoView() {
      return {
        icon: "mdi-account-group",
        title: this.$t("candidancy"),
        content: this.$t("candidancy_info"),
      };
    },
  },
  created() {
    if (
      this.$route.query.filter &&
      this.verifyFilter(this.$route.query.filter)
    ) {
      console.log("------------YES-------------");
      let urlFilter = this.$route.query.filter;
      urlFilter.limit = 10;

      this.filter = urlFilter;
      this.applyFilter();
    }
    if (this.$route.query.vacancyId) {
      console.log("this.$route.query.vacancyId", this.$route.query.vacancyId);
      this.vacancyId = this.$route.query.vacancyId;
      this.changeVacancy();
    }
    // this.appliedFilter = true
  },
  methods: {
    ...mapActions({
      updateVacancyCandidacyState: "vacancy/updateVacancyCandidacy",
    }),
    async fetchVacancies() {
      await this.$apollo.queries.vacancies.refetch();
    },
    sortByPontuation(array) {
      return array.sort(
        (a, b) => parseFloat(this.match(b)) - parseFloat(this.match(a))
      );
    },
    async movePage() {
      const { page, itemsPerPage } = this.options;
      console.log({ page, itemsPerPage });
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      this.filter.vacancyId = this.vacancyId;
      this.filter.ids = [];
      this.vacancies[index].candidacies.forEach((c) => {
        if (c.appliedFor) {
          this.filter.ids.push(c.candidate);
        }
      });
      this.filter.page = page;
      this.filter.limit =
        itemsPerPage === -1 ? this.candidates.totalDocs : itemsPerPage;
      try {
        return await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: GET_CANDIDATES_ENTITY_QUERY,
              variables: {
                filterCandidate: this.filter,
              },
              // fetchPolicy: "no-cache",
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      } catch (error) {
        console.error(error);
      }
    },

    getDataFromApi() {
      this.loading = true;
      this.movePage().then((response) => {
        console.log(response.data);
        this.candidates = response.data.candidates;
        this.loading = false;
      });
    },
    async searchCandidate(name) {
      try {
        return await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: GET_CANDIDATES_ENTITY_SEARCH,
              variables: {
                name: name,
                limit: 10,
              },
              // fetchPolicy: "no-cache",
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      } catch (error) {
        console.error(error);
      }
    },
    querySelections(name) {
      this.loading = true;
      this.searchCandidate(name).then((response) => {
        console.log(response.data);
        this.candidates = response.data.candidates;
        this.loading = false;
      });
    },
    itemSelected(table) {
      if (table.value) {
        // this.selectedCandidates = [];
        if (!table.item.blocked) {
          this.selectedCandidates.push(table.item);
        } else {
          // eslint-disable-next-line no-undef
          Fire.$emit("packageDialog", 1);
          // let successMsg = this.$t("Este candidato foi reprovado nesta vaga");
          // // eslint-disable-next-line no-undef
          // Fire.$emit("infoDialog", successMsg);
        }
      }
    },
    toggleSelectAll(table) {
      if (table.value) {
        this.selectedCandidates = [
          ...table.items.filter((item) => !item.blocked),
        ];
        if (table.items.filter((item) => item.blocked).length) {
          // eslint-disable-next-line no-undef
          Fire.$emit("packageDialog", 1);
          // let successMsg = this.$t(
          //   "O(s) candidato(s) desmarcado(s) foi(foram) reprovado(s) nesta vaga"
          // );
          // // eslint-disable-next-line no-undef
          // Fire.$emit("infoDialog", successMsg);
        }
      }
    },
    getStage(stage) {
      this.stage = stage.name;
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale(this.$i18n.locale).format("DD/MM/YYYY");
      }
      return "";
    },
    gotoUnsolicited() {
      this.$router.push({
        path: `/dashboard/entity/candidacies/unsolicited`,
      });
    },
    handleScroll() {
      // Pega a posição do scroll na vertical
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      this.scrollPosition = scrollTop;

      // Faça algo com a posição do scroll, por exemplo, exiba no console
      console.log("Posição do scroll:", scrollTop);
    },
    applyCandidateFilter(filter) {
      this.filter = {
        ...this.filter,
        areasInterest: filter.areasInterest,
        otherSkills: filter.otherSkills,
        province: filter.province ? `${filter.province}` : filter.province,
        formation: filter.formation,
        languages: filter.languages,
        maxYear: filter.maxYear,
        minYear: filter.minYear,
        maxSalary: filter.maxSalary,
        minSalary: filter.minSalary,
        professionalExperience: filter.professionalExperience,
        sector: filter.sector,
        limit: this.candidates.totalDocs,
      };
      // this.applyFilter();
      this.refetchCandidates();
    },
    async applyFilter() {
      this.loading = true;
      this.loadingData = true;
      this.$apollo.queries.candidates.skip = false;
      this.filter.page = 1;
      this.filter.limit = 10;
      this.filter.limit =
        this.options.itemsPerPage === -1
          ? this.candidates.totalDocs
          : this.options.itemsPerPage;

      await this.$apollo.queries.candidates.refetch();
      this.loading = false;
    },
    getAllMatch() {
      this.options.itemsPerPage = -1;
      this.faixas = [];
      if (!this.loading) {
        const arrayString = localStorage.getItem(this.vacancyId);
        if (arrayString) {
          const faixas = JSON.parse(arrayString);
          this.faixas = faixas;
        } else {
          this.createAutoList(this.candidates.candidates);
        }
      }
      // this.filter.limit = this.candidates.totalDocs;
      // this.refetchCandidates();
    },
    async refetchCandidates() {
      this.loadingData = true;
      this.$apollo.queries.candidates.skip = false;
      this.filter.page = 1;
      await this.$apollo.queries.candidates.refetch();
    },
    async applyVacanciesFilter() {
      this.vacancies_page = 1;
      this.filter.page = 1;
      this.filter.limit = 10;
      console.log(this.pageSize, this.vacancy.totalDocuments);
      if (this.pageSize <= this.vacancy.totalDocuments) {
        this.pageSize++;
      }
    },
    changeVacancy() {
      if (this.vacancies.length && this.vacancyId) {
        const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
        this.filter.vacancyId = this.vacancyId;
        this.filter.ids = [];
        this.vacancies[index].candidacies.forEach((c) => {
          if (c.appliedFor) {
            this.filter.ids.push(c.candidate);
          }
        });
        this.selectedCandidates = [];
        this.applyFilter();
        this.createAutoList(this.candidates.candidates);
      }
      // this.getVacancy();
      // console.log(this.filter.ids);
    },
    search(vacancies) {
      this.vacancies = [...vacancies];
      console.log(this.vacancies);
      // this.changeVacancy();
      // this.setVacancy(this.vacancyId);
    },
    getLanguagePerc(candidate) {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      let vacancy = this.vacancies[index];
      return this.getLanguageVacancyPerc(candidate, vacancy);
    },

    getExpCandPer(candidate) {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      let vacancy = this.vacancies[index];
      return this.getExpCandVacancyPer(candidate, vacancy);
    },

    getFormationPerc(candidate) {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      let vacancy = this.vacancies[index];
      return this.getFormationVacancyPerc(candidate, vacancy);
    },

    getAreaCandPer(candidate) {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      let vacancy = this.vacancies[index];
      return this.getAreaCandVacancyPer(candidate, vacancy);
    },

    match(candidate) {
      if (this.getCandidancy(candidate.id)) {
        let half = 1;
        if (
          this.getCandidancy(candidate.id).tests.filter((t) => t.stage)
            .length == 0
        ) {
          half = 1;
        } else {
          half = 0.5;
        }

        let value =
          (this.getAreaCandPer(candidate) +
            this.getLanguagePerc(candidate) +
            this.getFormationPerc(candidate) +
            this.getExpCandPer(candidate)) *
          half;
        if (
          this.getCandidancy(candidate.id).tests.filter((t) => t.stage).length
        ) {
          let result = this.calculePontuation(
            this.getCandidancy(candidate.id).tests.filter((t) => t.stage)
          );
          value = value + parseFloat(result);
        }

        return parseFloat(value); //this.DFormatter(value);
      } else {
        return parseFloat(0); //this.DFormatter(0);
      }
    },
    calculePontuation(tests) {
      let total = tests.length;
      let totaScoreObsrvation = 0;
      let result =
        tests.reduce((accumulator, item) => {
          if (!accumulator) {
            accumulator = 0;
          }
          if (!item.result) {
            item.result = 0;
          }
          if (item.type == "psychological" || item.type == "technician") {
            console.log(item.type, item.questions);
            let cal = item.questions.find((q) => q.openQuestion === true);
            if (cal) {
              // console.log(cal);
              if (!item.stage) {
                totaScoreObsrvation = cal.observations.length
                  ? cal.observations
                      .map((obs) => {
                        return obs.result;
                      })
                      .map(Number)
                      .reduce((acc, curr) => acc + curr, 0)
                  : 0;
              } else {
                item.questions.forEach((q) => {
                  if (item.questions.length) {
                    // if (q.openQuestion === true) {
                    q.observations.forEach((element) => {
                      totaScoreObsrvation += parseFloat(element.result);
                    });
                    // }
                  }
                });
              }
              console.log(
                parseFloat(totaScoreObsrvation),
                parseFloat(item.result),
                parseFloat(accumulator)
              );
              // if (cal.observations.length) {
              //   totaScoreObsrvation = totaScoreObsrvation / 4;
              // }
              return (
                parseFloat(accumulator) +
                parseFloat(item.result) +
                parseFloat(totaScoreObsrvation)
              );
            } else {
              // total = total - 1;
              console.log("tecn", parseFloat(accumulator));
              return parseFloat(accumulator) + parseFloat(item.result);
            }
          } else if (item.result) {
            return accumulator + item.result;
          } else if (!this.days_(item)) {
            return accumulator + 0;
          } else {
            total = total - 1;
            return accumulator + 0;
          }
        }, 0) / total;
      result = result * 0.75;
      return result >= 0 ? Number(result.toFixed(2)) : 0;
    },
    days_(test) {
      if (test.status) {
        return { status: "Concluido", color: "success" };
      }

      const testDate = test.date + " " + test.time;
      const finalDate = moment(testDate, "YYYY-MM-DD H:mm").locale("pt");
      const now = moment().format("YYYY-MM-DD H:mm");

      return now < finalDate._i;
    },
    openDialogEmail(candidate) {
      this.candidate.candidate = candidate;
      this.dialogEmail = true;
    },
    openDialogNote(candidate) {
      this.candidate.candidate = candidate;
      this.dialogNote = true;
    },
    closeAddDialog() {
      this.show = false;
      this.dialogEmail = false;
    },
    sendEmailDone() {
      this.closeAddDialog();
      this.success = this.$t("email_sent_successfully");
      this.showSuccess = true;
    },
    DFormatter(num) {
      let mtnLocale = Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num);
      return mtnLocale;
    },

    checkedCandidate(id) {
      console.log("candidateId: ", id);
      const index = this.selectedCandidates.findIndex((c) => c.id == id);
      if (index > -1) {
        this.selectedCandidates.splice(index, 1);
      } else {
        const candidate = this.filteredItems.find((c) => c.id == id);
        this.selectedCandidates.push(Object.assign({}, candidate));
      }
    },
    checkSelectAll() {
      if (this.selectAll) {
        for (let index = 0; index < this.filteredItems.length; index++) {
          if (!this.filteredItems[index].blocked) {
            this.selectedCandidates.push(
              Object.assign({}, this.filteredItems[index])
            );
          }
        }
      } else {
        this.selectedCandidates = [];
      }
    },
    fetchMore() {
      this.filter.page += 1;
      this.$apollo.queries.candidates.fetchMore({
        variables: {
          filter: this.filter,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.candidates.candidates = [
            ...this.candidates.candidates,
            ...fetchMoreResult.candidates.candidates,
          ];
          return {
            candidates: {
              candidates: [...this.candidates.candidates],
              totalDocs: fetchMoreResult.candidates.totalDocs,
              totalPages: fetchMoreResult.candidates.totalPages,
              blockeds: fetchMoreResult.candidates.blockeds,
            },
          };
        },
      });
    },
    setVacancy(vacancyId) {
      if (vacancyId) {
        this.applyVacanciesFilter();
        this.vacancyId = vacancyId;
        this.changeVacancy();
        this.filter.favorites = undefined;

        this.selectedCandidates = [];
      }
    },
    getCandidancy(id) {
      if (this.vacancy)
        return this.vacancy.candidacies.find((c) => c.candidate === id);
    },
    async deleteList(id) {
      try {
        this.confirm = false;
        await this.$apollo.mutate({
          mutation: DELETE_VACANCY_LIST_MUTATION,
          variables: { id: this.vacancyId, listId: id },
        });
        const index = this.lists.findIndex((l) => l.id == id);
        this.lists.splice(index, 1);
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    async removeCandidate(id, candidateId) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: REMOVE_CANDIDATE_FROM_VACANCY_LIST_MUTATION,
          variables: {
            id: this.vacancyId,
            listId: id,
            candidateId: candidateId,
          },
        });
        console.log(data);
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    async addCandidateList(id, candidateId) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_CANDIDATE_TO_VACANCY_LIST_MUTATION,
          variables: {
            id: this.vacancyId,
            listId: id,
            candidateId: candidateId,
          },
        });
        const index = this.lists.findIndex((l) => l.id == id);
        this.lists[index].candidates =
          data.addCandidateToVacancyList.candidates;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    generateSimilarCode(length = 24) {
      let code = "";
      const charset = "0123456789abcdef";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        code += charset[randomIndex];
      }

      return code;
    },
    createAutoList(candidates) {
      const candidatos = candidates;
      this.faixa_60_80 = [];
      this.faixa_80_90 = [];
      this.faixa_90_100 = [];
      this.faixas = [];
      candidatos.forEach((candidato) => {
        let pontuacao = parseFloat(this.match(candidato));
        // console.log("pontuacao", pontuacao);
        if (pontuacao >= 60 && pontuacao < 80) {
          // Verificar se o id do candidato não existe em faixa_60_80 e fazer push se necessário
          if (this.faixa_60_80 && !this.faixa_60_80.includes(candidato.id)) {
            this.faixa_60_80.push(candidato.id);
            // this.createList("faixa_60_80",candidato.id)
          }
        } else if (pontuacao >= 80 && pontuacao < 90) {
          if (this.faixa_80_90 && !this.faixa_80_90.includes(candidato.id)) {
            this.faixa_80_90.push(candidato.id);
            //  this.createList("faixa_80_90",candidato.id)
          }
        } else if (pontuacao >= 90 && pontuacao <= 100) {
          if (this.faixa_90_100 && !this.faixa_90_100.includes(candidato.id)) {
            this.faixa_90_100.push(candidato.id);
            // this.createList("faixa_90_100",candidato.id)
          }
        }
      });
      if (this.faixa_60_80.length) {
        this.faixas.push({
          id: this.generateSimilarCode(),
          name: this.$t("faixa_60_80"),
          candidates: this.faixa_60_80,
          enable: true,
        });
      }
      if (this.faixa_80_90.length) {
        this.faixas.push({
          id: this.generateSimilarCode(),
          name: this.$t("faixa_80_90"),
          candidates: this.faixa_80_90,
          enable: true,
        });
      }
      if (this.faixa_90_100.length) {
        this.faixas.push({
          id: this.generateSimilarCode(),
          name: this.$t("faixa_90_100"),
          candidates: this.faixa_90_100,
          enable: true,
        });
      }

      if (this.faixas.length) {
        const arrayString = JSON.stringify(this.faixas);
        localStorage.setItem(this.vacancyId, arrayString);
      }
    },
    async fetchAutoList(update) {
      if (update) {
        await this.$apollo.queries.vacancies.refetch();
      }
    },
    async createList(name, id) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_VACANCY_LIST_MUTATION,
          variables: { id: this.vacancyId, candidateId: id, name: name },
        });
        console.log(data);
        // candidates.forEach(candidate=>{
        //   this.addCandidateList(data.createVacancyList.id,candidate);
        // })
        await this.$apollo.queries.vacancies.refetch();
        return 1;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    verifyFilter(filter) {
      let count = 0;
      for (const key in filter) {
        switch (key) {
          case "formation":
            if (filter[key][0].course) {
              count++;
            }
            if (filter[key][0].level) {
              count++;
            }
            break;
          case "languages":
            if (filter[key][0].name) {
              count++;
            }
            if (filter[key][0].level) {
              count++;
            }
            break;
          case "limit":
            break;
          case "page":
            break;

          default:
            if (filter[key]) {
              count++;
            }
            break;
        }
      }
      return count > 0;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    const el = document.getElementById("sensor");
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      if (this.candidates && this.filter.page <= this.candidates.totalPages) {
        // this.fetchMore();
      }
    });
  },
};
</script>
